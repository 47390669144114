
.about {
 background-color: #F4F4F4;
}
.about__first-block {
 padding-bottom: 40px;
}
.about__text {
 font: 400 16px/150% "Lato", sans-serif;
 color: #000;
 padding-bottom: 25px;
}
.about__text:last-child {
 padding-bottom: 0;
}
.about__text span {
 color: #BD0A2F;
}
.about__text--list {
 padding-left: 18px;
 position: relative;
}
.about__text--list::before {
 content: "";
 position: absolute;
 left: 0;
 top: 50%;
 transform: translateY(-50%);
 width: 8px;
 height: 8px;
 border-radius: 8px;
 background: #BD0A2F;
}
.about__text--pl {
 padding: 5px 0 5px 18px;
}
.about__text--pl:last-child {
 padding-bottom: 5px;
}
.about__text-wrapper {
 /*padding: 5px 0;*/
}
.about__title-red {
 font: 700 32px/34px "Lato", sans-serif;
 color: #BD0A2F;
 width: 100%;
 text-align: center;
 padding: 12px 0 5px 0;
}
.about__list {
 padding: 13px 0;
}
.about__item {
 padding-bottom: 1px;
}
.about__item:last-child {
 padding-bottom: 0;
}

.about-grid {
 background-color: #fff;
 width: 100%;
 overflow: auto;
 margin-bottom: 30px;
}
.about-grid__block {
 padding-top: 50px;
 padding-bottom: 20px;
 gap: 20px 30px;
 display: grid;
 align-items: center;
 justify-items: center;
 grid-template-areas: "y1 p1-1 ic2 y3 ic4 p2-1 y5" "y1d1 p1-1 ic2 y3d1 ic4 p2-1 y5d1" "y1d2 p1-2 ic2 y3d2 ic4 p2-2 y5d2" "y1d3 p1-2 y2 y3d3 y4 p2-2 y5d3" ".p1-3 y2d1 .y4d1 p2-3 ." "ic1 p1-3 y2d2 ic3 y4d2 p2-3 ic5" "ic1 p1-4 y2d3 ic3 y4d3 p2-4 ic5" "ic1 p1-4 .ic3 .p2-4 ic5";
}
@media all and (max-width: 1023px) {
 .about-grid__block {
  gap: 15px;
 }
}
@media all and (max-width: 767px) {
 .about-grid__block {
  grid-template-areas: "y1 ic2 y3 ic4 y5" "y1d1 ic2 y3d1 ic4 y5d1" "y1d2 ic2 y3d2 ic4 y5d2" "y1d3 y2 y3d3 y4 y5d3" "ic1   y2d1 ic3 y4d1  ic5" "ic1  y2d2 ic3 y4d2  ic5" "ic1 y2d3 ic3 y4d3  ic5";
 }
}
.about-grid__year {
 font: 600 32px/32px "Lato", sans-serif;
 color: #BD0A2F;
 text-align: center;
}
.about-grid__desc-text {
 font: 600 15px/20px "Lato", sans-serif;
 color: #000;
 text-align: center;
}
.about-grid__desc-title {
 font: 400 11px/15px "Lato", sans-serif;
 color: #333333;
 text-align: center;
 text-transform: uppercase;
}
.about-grid img {
 max-width: 135px;
}

.y1 {
 grid-area: y1;
}

.y2 {
 grid-area: y2;
}

.y3 {
 grid-area: y3;
}

.y4 {
 grid-area: y4;
}

.y5 {
 grid-area: y5;
}

.ic1 {
 grid-area: ic1;
}

.ic2 {
 grid-area: ic2;
}

.ic3 {
 grid-area: ic3;
}

.ic4 {
 grid-area: ic4;
}

.ic5 {
 grid-area: ic5;
}

.p1-2 {
 grid-area: p1-2;
}

.p1-1 {
 grid-area: p1-1;
 align-self: center;
}

.p1-3 {
 grid-area: p1-3;
 align-self: center;
}

.p1-4 {
 grid-area: p1-4;
 align-self: center;
}

.p2-1 {
 grid-area: p2-1;
 align-self: center;
}

.p2-2 {
 grid-area: p2-2;
 align-self: center;
}

.p2-3 {
 grid-area: p2-3;
 align-self: center;
}

.p2-4 {
 grid-area: p2-4;
 align-self: center;
}

.y1d1 {
 grid-area: y1d1;
}

.y1d2 {
 grid-area: y1d2;
}

.y1d3 {
 grid-area: y1d3;
}

.y2d1 {
 grid-area: y2d1;
}

.y2d2 {
 grid-area: y2d2;
}

.y2d3 {
 grid-area: y2d3;
}

.y3d1 {
 grid-area: y3d1;
}

.y3d2 {
 grid-area: y3d2;
}

.y3d3 {
 grid-area: y3d3;
}

.y4d1 {
 grid-area: y4d1;
}

.y4d2 {
 grid-area: y4d2;
}

.y4d3 {
 grid-area: y4d3;
}

.y5d1 {
 grid-area: y5d1;
}

.y5d2 {
 grid-area: y5d2;
}

.y5d3 {
 grid-area: y5d3;
}

@media all and (max-width: 767px) {
 .p1-1, .p1-2, .p1-3, .p1-4,
 .p2-1, .p2-2, .p2-3, .p2-4 {
  display: none;
 }
}
@media all and (max-width: 1199px) {
 .about__text {
  padding-bottom: 22px;
 }
 .about__title-red{
  padding: 26px 0 5px 0;
 }
}
@media all and (max-width: 575px) {
 .about__text{
  font: 400 14px/18px "Lato", sans-serif;
  padding-bottom: 18px;
 }
 .about__title-red{
  font: 700 21px/34px "Lato", sans-serif;
  padding: 26px 0 0px 0
 }
}
/* .about-grid {
    background-color: #fff
}

.about-grid__picture {
    gap: 30px;
    display: grid;
    grid-template-areas: "y1 p1-1 ic2 y3 ic4 p2-1 y5"
        "y1d1 p1-1 ic2 y3d1 ic4 p2-1 y5d1"
        "y1d2 p1-2 ic2 y3d2 ic4 p2-2 y5d2"
        "y1d3 p1-2 y4 y3d3 y5 p2-2 y5d3"
        ". p1-3 y4d1 . y5d1 p3-3 ."
        "ic1 p1-3 y4d2 ic3 y5d2 p3-3 ic5"
        "ic1 p1-4 y4d3 ic3 y5d3 p3-4 ic5"
        "ic1 p1-4 . ic3 . p3-4 ic5"
} */
