.team-page
  background-color: #F4F4F4
  [data-id="page"]
    padding-bottom: 0
.team-banner
  position: relative
  padding: 58px 0 67px 0
  height: 275px

  &__img
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center

.team-title-block
  color: #fff

  h1
    letter-spacing: -1px
    font: 700 44px/64px "Lato", sans-serif
    margin-bottom: 10px

  h3
    font: 700 16px/26px "Lato", sans-serif
    max-width: 380px
    width: 100%

.team-block
  background-color: #FFFFFF
  padding: 20px
  display: flex
  border-radius: 3px
  margin-bottom: 30px
  @media only screen and (max-width: 767px)
    margin: 0 -16px 20px -16px
  @media only screen and (max-width: 575px)
    flex-direction: column
    padding: 30px 16px 16px 16px

  &__image
    flex: 0 0 130px
    margin-right: 70px
    width: 130px
    height: 130px
    @media only screen and (max-width: 767px)
      margin-right: 39px
    @media only screen and (max-width: 575px)
      margin-right: 0
      margin-bottom: 20px
      display: flex
      flex: 1 1 auto
      width: 100%
      justify-content: center
    img
      width: 100%
      height: 100%
      object-fit: cover
      @media only screen and (max-width: 575px)
        width: auto
        height: auto
        object-fit: none
  &__body
    padding-top: 5px
    position: relative
    @media only screen and (max-width: 575px)
      padding-left: 25px
    &:before
      content: ''
      position: absolute
      left: -22px
      top: 0
      width: 20px
      height: 15px
      background-image: url("../../assets/images/backtick.png")
      @media only screen and (max-width: 575px)
        left: 0
  &__text
    font: 400 15px/21px "Lato", sans-serif
    margin-bottom: 20px
    @media only screen and (max-width: 767px)
      font: 400 14px/18px "Lato", sans-serif
      margin-bottom: 15px

  &__name-block
    width: 100%
    display: flex
    justify-content: flex-end

  &__name
    border-top: 1px solid #B6BABD
    padding-top: 7px
    max-width: 215px
    width: 100%
    color: #273D54
    @media only screen and (max-width: 767px)
      max-width: 100%
    .name
      font: 600 15px/16px "Lato", sans-serif
    .status
      color: #626060
      font: 400 14px/17px "Lato", sans-serif

.team-card-area
  display: flex
  justify-content: center
  padding-bottom: 5px
  @media only screen and (max-width: 767px)
    padding-bottom: 30px

.team-card-slider
  max-width: 695px
  width: 100%
  @media only screen and (max-width: 767px)
    max-width: 475px
  @media only screen and (max-width: 575px)
    max-width: 100%

.team-card
  //margin: 0 15px
  display: flex
  margin: 0 auto
  align-items: center
  flex-direction: column
  padding: 20px
  background-color: #FFFFFF
  border-radius: 3px
  width: 210px
  .team-block__image
    margin-right: 0
    margin-bottom: 15px
  &__name
    color: #000
    text-align: center
    font: 700 16px/19px "Lato", sans-serif
    margin-bottom: 2px
  &__status
    color: #626060
    text-align: center
    font: 400 14px/150% "Lato", sans-serif

.section-life
  background-color: #F4F4F4