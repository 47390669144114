.ui-page
  display: flex
  justify-content: center
  align-items: center


.ui-icon-area
  display: flex
  width: 500px
  flex-wrap: wrap

.ui-icon
  border: 1px solid
  width: 30px
  height: 30px
  margin: 3px
  background: #ccc
  display: flex
  justify-content: center
  align-items: center
