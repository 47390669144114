.error-block__title
  color: #38454e
  display: inline-block
  font: 700 3.25rem/4.1875rem Lato,sans-serif
  margin-bottom: 1.75rem
  @media all and (max-width: 767px)
    font: 700 3.25rem/3.3rem Lato,sans-serif
    margin-bottom: 1.5rem
.error-block__text
  color: #38454e
  font: 700 1.625rem/130% Lato,sans-serif
  letter-spacing: -.0625rem
  margin-bottom: 2.1875rem
