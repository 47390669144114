.section-reviews
  background-color: #fff
  padding: 20px 0 20px 0
  [data-id="title-area"]
    margin-bottom: 20px
    padding-bottom: 0
.reviews-area
  display: flex

.review-card
  display: flex
  flex-direction: column
  border: 1px solid #8C9398
  border-radius: 3px
  padding: 20px
  &__header
    display: flex
    margin-bottom: 15px
  &__image
    flex: 0 0 130px
    height: 130px
    margin-right: 20px
    img
      object-fit: cover
      width: 100%
      height: 100%
  &__info
    display: flex
    flex-direction: column
    .name
      font: 400 18px/21px "Lato", sans-serif
      margin-bottom: 2px
    .status
      color: #626060
      font: 400 14px/150% "Lato", sans-serif
      margin-bottom: 3px
    .stage
      color: #273D54
      font: 700 14px/180% "Lato", sans-serif

  &__body
    p
      color: #000000
      font: 400 15px/21px "Lato", sans-serif
