// $middle: 'max-width: 1139.9px'
// $table: 'max-width: 1023.9px'
// $mobile: 'max-width: 767.9px'

$extraLarge: "min-width: 1200px"
$large: "max-width: 1199px"
$medium: "max-width: 1023px"
$small: "max-width: 767px"
$extraSmall:  "max-width: 575px"


.container
  width: 100%
  margin-left: auto
  margin-right: auto
  max-width: 1128px
  padding-left: 18px
  padding-right: 18px
  @media all and ($large)
    max-width: 100%
    padding-left: 16px
    padding-right: 16px
  @media all and ($medium)
    max--width: 100%
    padding-left: 16px
    padding-right: 16px
  @media all and ($small)
    max-width: 100%
    padding-left: 16px
    padding-right: 16px
  @media all and ($extraSmall)
    max-width: 100%
    padding-left: 16px
    padding-right: 16px
    
  
.grid.container
  width: auto
  margin-left: auto
  margin-right: auto
  max-width: 1128px
  padding-left: 18px
  padding-right: 18px
  @media all and ($large)
    min-width: auto
    padding-left: 16px
    padding-right: 16px
  @media all and ($medium)
    min-width: auto
    padding-left: 16px
    padding-right: 16px
  @media all and ($small)
    min-width: auto
    padding-left: 16px
    padding-right: 16px
  @media all and ($extraSmall)
    min-width: auto
    padding-left: 16px
    padding-right: 16px