//.form.form-modal
//  margin-bottom: 0.9375rem
//  box-shadow: none
//  padding: 0
//

.form__text-d
  color: #BD0A2F
  font: 400 15px/19px 'Lato', sans-serif
  letter-spacing: -0.5px
  @media only screen and (max-width: 1199px)
    font: 400 13px/17px 'Lato', sans-serif
  @media only screen and (max-width: 1023px)
    font: 400 12px/13px 'Lato', sans-serif
.form
  //margin-bottom: 6.125rem
  background-color: #ffffff
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2)
  border-radius: 3px
  padding: 35px 40px 50px 34px
  position: relative
  z-index: 5
  @media only screen and (max-width: 1199px)
    padding: 38px 20px 40px 20px
  @media only screen and (max-width: 1023px)
    padding: 25px 15px 30px 15px
  &__group
    display: flex
    flex-wrap: wrap
    justify-content: space-between
  &__field
    position: relative
    display: flex
    flex-flow: column-reverse
  //margin-bottom: 0.5em


  &__input
    width: 100%
    transition: all 0.2s
    touch-action: manipulation
    min-height: 31px
    font: 400 15px/15px 'Lato', sans-serif
    border-bottom: 1px solid #B6BABD
    cursor: text
    &.simple-select.css-b62m3t-container
      width: 100%
    &:focus
      outline: 0
      border-bottom: 1px solid #666
    &-error
      border-color: #BD0A2F
  &__label
    color: #373737
    transition: all 0.2s
    touch-action: manipulation
    font: 400 15px/21px 'Lato', sans-serif
    span
      color: #BD0A2F
      position: relative

  &__input-container
    flex: 0 1 310px
    margin: 25px 0px 0px 0px
  &__error
    display: none
    color: #BD0A2F
    font: 400 0.875rem/1rem 'Lato', sans-serif
    &-visible
      display: inline-block
  &__text-red
    font: 400 18px/32px 'Lato', sans-serif
    @media only screen and (max-width: 1199px)
      margin-bottom: 10px
      font: 400 16px/19px "Lato", sans-serif
    span
      color: #BD0A2F
  &__text-blue
    font: 400 0.9375rem/130% 'PT Sans', sans-serif
    color: #BD0A2F
  &__button__red
    flex: 0 0 auto
    width: 100%
    min-height: 2.8125rem
    font: 700 1.125rem/1.25rem 'Lato', sans-serif
  //&__empty-block
  //  height: 20px
  //  display: flex
  //  position: relative
  //  top: -4px
  .button__file, .button__red
    color: #373737
    font: 600 13px/20px "Lato", sans-serif
    text-transform: uppercase
    width: 100%
    padding: 12px
    //max-width: 308px
    position: relative
    @media only screen and (max-width: 1199px)
      //max-width: 275px
    @media only screen and (max-width: 767px)
      //width: 225px
      padding: 5px 15px
      font: 700 12px/20px "Lato", sans-serif
    input
      cursor: pointer
      height: 100%
      left: 0
      opacity: 0
      position: absolute
      top: 0
      width: 100%
  .button__red
    font: 700 14px/20px "Lato", sans-serif
    background-color: #BD0A2F
    color: #fff
    @media only screen and (max-width: 767px)
      padding: 5px 15px
      font: 700 12px/20px "Lato", sans-serif
      height: auto
      min-height: auto
  .css-1fbt51-control
    height: 2rem
    min-height: 2rem
    max-height: 2rem
    padding-left: 0
  .css-14dclt2-Input,
  .css-qc6sy-singleValue
    margin-left: 0
    padding-left: 0
    font: 400 0.9375rem/0.9375rem 'Lato', sans-serif
.select-icon
  padding: 0 0 0 10px

.form__info
  @media only screen and (max-width: 1199px)
    padding-right: 10px
  @media only screen and (max-width: 1023px)
    width: 215px
  @media only screen and (max-width: 767px)
    padding-right: 0
    width: 225px

.form-file
  color: #273D54
  font: 400 13px/13px "Lato", sans-serif
  letter-spacing: -0.3px
  max-width: 200px
  width: 100%
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  margin-bottom: 10px

.form-main
  position: relative
  z-index: 15
  input::placeholder
    display: none
    color: red
  input
    font: 400 15px/15px 'Lato', sans-serif
    z-index: 2
    background-color: transparent
  .form__field.form__no-empty .form__label,
  .form__input:focus ~ .form__label,
  .react-datepicker__tab-loop ~ .form__label
    top: -20px
    opacity: 1
    @media only screen and (max-width: 1199px)
      top: -18px
  .form__label
    z-index: 1
    top: -1px
    left: 0px
    opacity: 1
    transition-duration: .3s
    transform: translateZ(0)
    position: absolute
    @media only screen and (max-width: 1199px)
      top: 4px

//.form-select
//  max-width: 310px
//  @media only screen and (max-width: 1199px)
//    max-width: 275px

.form-col
  flex: 0 1 30%
  @media all and (max-width: 767px)
    flex: 0 1 48%
  @media all and (max-width: 575px)
    flex: 0 1 100%
.vis
  @media all and (max-width: 767px)
    display: none
.react-datepicker-wrapper
  z-index: 2

.react-datepicker-popper
  z-index: 999

.form__field
  .react-datepicker__input-container
    input:focus
      color: transparent
  &.form__no-empty
    .react-datepicker__input-container
      input:focus
        color: #333

input
  &::placeholder
    background-color: #fff
    font: 400 1.125rem/1.5rem 'Lato', sans-serif

.select-placeholder
  background-color: #fff
  font: 400 1.125rem/1.5rem 'Lato', sans-serif

.team
  &__other

.team-main
  margin-bottom: 4.0625rem
  &__image
    max-width: 21.875rem
    width: 100%
    height: 10.625rem
    margin-right: 7.5rem
  &__author
  &__block
    margin-top: 0.9375rem
    padding-top: 0.4375rem
    width: 13.75rem
    border-top: 1px solid #B8BAC1
  &__info
    padding-top: 0.3125rem
  &__text
    color: #333333
    font: 400 1rem/1.5rem 'Lato', sans-serif
  &__name
    white-space: nowrap
    color: #1D293F
    font: 700 0.9375rem/1.11875rem 'Lato', sans-serif
  &__post
    white-space: nowrap
    color: #197BBD
    font: 400 0.9375rem/1.25rem 'Lato', sans-serif
  &__com
    transform: translateX(-100%)
    position: absolute
    left: -1.25rem
    top: 0

.team-other
  margin-bottom: 1.25rem
  &__item
    margin-right: 0.9375rem
    margin-left: 0.9375rem
    margin-bottom: 1.875rem
  &__image
    width: 11.875rem
    height: 100%
    margin-bottom: 1.25rem
    img
      max-width: 100%
  &__name
    white-space: nowrap
    color: #1D293F
    font: 400 1.0625rem/130% 'Lato', sans-serif
  &__post
    white-space: nowrap
    color: #2F5379
    font: 400 0.875rem/150% 'Lato', sans-serif

//.filter
//  padding-bottom: 0.3125rem
//  z-index: 10
//  //border: 1px solid red
//  position: sticky
//  top: 0
//  padding-right: 1.875rem
//  &__title
//    color: #2F5379
//    margin-bottom: 0.3125rem
//    font: 400 1.5rem/1.75rem 'Lato', sans-serif
//  &__button
//    padding: 0
//    background: transparent
//    font: 400 0.9375rem/1.125rem 'Lato', sans-serif
//    border-radius: 0
//    color: #38454E
//    text-decoration-line: underline
//  &__block
//    font: 400 0.9375rem/1.125rem 'Lato', sans-serif
//
//.filter-drop-block
//  top: 0
//  width: fit-content
//  display: none
//  transition: 0.5s ease all
//  //opacity: 0
//  position: absolute
//  background-color: #8C97AC
//  right: -10.5rem
//  z-index: 10
//  border-radius: 0.3125rem
//  &__body
//    text-decoration-line: none
//    display: block
//    position: relative
//    padding: 0.9375rem 2.5rem 0.9375rem 1.25rem
//  &__vector
//    left: -0.85rem
//    top: 0.75rem
//    position: absolute
//    transform: rotate(-90deg)
//    width: fit-content
//    img
//      height: 0.5rem
//      width: 1.1875rem
//  &__close
//    cursor: pointer
//    position: absolute
//    top: 0.5rem
//    right: 0.5rem
//  &__title
//    font: 700 0.9375rem/18px 'Lato', sans-serif
//    color: #F8FAFF
//    margin-bottom: 0.5rem
//  &__result
//    cursor: pointer
//    color: #F8FAFF
//    font: 400 0.9375rem/18px 'Lato', sans-serif
//
//.filter-block
//  transition: 0.3s ease all
//  //width: 14.6875rem
//  background: #EDF5FE
//  padding: 1.0625rem
//  font: 400 1.3125rem/1.5625rem 'Lato', sans-serif
//  color: #38454E
//  margin-bottom: 0.625rem
//  &__title
//    cursor: pointer
//    color: #38454E
//    font: 400 1.3125rem/1.5625rem 'Lato', sans-serif
//    span
//      word-break: break-all
//  //margin-bottom: 0.625rem
//  &__arrow
//    transition: 0.3s ease all
//    transform: rotate(180deg)
//    cursor: pointer
//    padding: 0.3125rem
//  &__list
//    transition: 0.3s ease all
//    opacity: 1
//    height: 100%
//    margin-top: 0.625rem
//  //margin-bottom: 1.25rem
//  &__item
//  &__button
//    margin-left: 1.875rem
//  &--close
//    background-color: #fff
//    .filter-block__list
//      opacity: 0
//      margin-top: 0
//      max-height: 0
//      overflow: hidden
//    //display: none
//    .filter-block__arrow
//      transform: rotate(0deg)
//  .check-task
//    align-items: baseline !important
//
//.pagination
//  &__link-page
//    flex: 1 1 50%
//  &__link-text
//    font: 400 0.8125rem/1rem 'Lato', sans-serif
//  &__pagination
//    flex: 1 1 50%
//  &__form
//    max-width: 3.5625rem
//    width: 100%
//    height: 1.875rem
//  &__button__gray
//    padding-left: 0.3125rem
//    padding-right: 0.3125rem
//    min-height: 1.875rem
//    max-width: 5.875rem
//    width: 100%
//  &__num
//    text-decoration-line: none
//    margin: 0 0.125rem
//    border-radius: 0.3125rem
//    font: 600 0.9375rem/1.125rem 'Lato', sans-serif
//    color: #197BBD
//    padding: 0.5rem 0.5625rem
//    &:hover,
//    &:active,
//    &--active
//      color: #fff
//      background-color: #197BBD

.simple-select.css-b62m3t-container
  width: 100%
  //height: 2rem!important
  .css-1d8n9bt
    padding: 0
  .css-1s2u09g-control
    min-height: 2.1875rem
    line-height: 1.5rem !important
    //height: 2rem
    background-color: transparent
    border: none
  .css-1pahdxg-control
    min-height: 2.1875rem
    line-height: 1.5rem !important
    box-shadow: none
    border: none
  .css-1okebmr-indicatorSeparator
    display: none
  .css-319lph-ValueContainer
    padding: 0
  .css-14el2xx-placeholder
    white-space: nowrap
    margin: 0
  .css-14dclt2-Input
    input
      width: auto
      font: 300 1.125rem/1.5rem "Lato", sans-serif
  .css-1s2u09g-control
//background-color: transparent

.search-skeleton
  min-height: 2.625rem
  border-radius: 0.625rem
  width: 100%

.vacancy-skeleton
  min-height: 18.75rem
  border-radius: 0.625rem

.simple-select.form__input
  padding-left: 0

.form-interview-success
  &__title
    color: #38454E
    font: 700 27px/32px "Lato", sans-serif
    margin-bottom: 26px
  &__info
    color: #000
    font: 400 15px/21px "Lato", sans-serif
  &__button-area
    display: flex
    justify-content: center
  &__button__red
    max-width: 20rem
    width: 100%
    min-height: 2.8125rem
    font: 400 1.125rem/1.25rem "Lato", sans-serif

.form-vector
  position: absolute
  top: 0
  z-index: 0
  right: -32%

.react-datepicker-popper
  .react-datepicker__triangle
    display: none
  .react-datepicker__header
    background-color: #E8F3FE
  select
    margin: 0 0.3125rem

.cookie-area
  position: fixed
  z-index: 100
  inset: auto 1.25rem 1.25rem auto
  background-color: #f0ede8
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%)
  max-width: 400px
  width: 100%

.cookie
  padding: 15px 25px
  position: relative
  &__text
    color: #000000
    font-family: 'Lato', sans-serif
    font-size: 0.9375rem
    font-weight: 300
  &__button
    position: absolute
    top: 0.425rem
    right: 0.425rem
    border-radius: 50%
    background: #cacfd9
    padding: 5px

.history-important
  margin-bottom: 70px
  &__title
    text-align: center
    font: 700 1.6875rem/150% "Lato", sans-serif
    margin-bottom: 12px
    color: #BD0A2F
  &__list
    display: flex
    flex-direction: column
  &__item
    padding-left: 30px
    position: relative
    margin-bottom: 2px
    @media all and (max-width: 567.9px)
      padding-left: 15px
      margin-bottom: 5px
    &::after
      content: ''
      width: 8px
      height: 8px
      border-radius: 50%
      background-color: #BD0A2F
      left: 0
      top: 7px
      position: absolute
  &__text
    margin-bottom: 0
    color: #333333

.form-main-area
  position: relative
  @media all and (max-width: 575px)
    margin: 0 -16px

.w
  margin-top: 38px
  margin-bottom: 10px
  @media all and (max-width: 1199px)
    margin-top: 20px
  @media all and (max-width: 1023px)
    margin-top: 15px
  @media all and (max-width: 767px)
    margin-top: 12px
  @media all and (max-width: 575px)
    margin-top: 10px

@media all and (max-width: 575px)
  .form__input-container,
  .form-select
    max-width: 100%
    flex: 1 1 100%
  .form
    box-shadow: none
    padding: 0 15px 30px 15px
  .form__text-red
    display: none
  .form .button__file, .form .button__red
    width: 100%
    max-width: 100%
  .form .button__file
    margin-bottom: 10px
  .form__warning .form__text-blue
    margin-bottom: 15px
  .form__info
    width: 100%

