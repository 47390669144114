.mySwiper2
  margin-bottom: 20px
  max-height: 525px
  @media only screen and (max-width: 767px)
    display: none

  .swiper-wrapper
    max-height: 525px
    height: auto

  .swiper-slide, swiper-slide
    height: auto

.mySwiper
  .swiper-slide
    height: 110px
    border-radius: 3px
    overflow: hidden
    @media only screen and (max-width: 767px)
      height: 385px
    @media only screen and (max-width: 575px)
      height: 155px
    &:before
      transition: 0.2s ease background-color
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: #FFFFFF
      opacity: 0.4
      @media only screen and (max-width: 767px)
        display: none
    img
      object-fit: cover
      height: 100%
      width: 100%

  .swiper-slide-thumb-active.swiper-slide:before
    opacity: 0
