.catalog-search
  width: 100%
  display: flex
  margin-bottom: 25px
  &__body
    width: 100%
    display: flex
    align-items: center
    background: #FFFFFF
    border: 1px solid #DBDBDB
    border-right: none
    border-radius: 3px 0 0 3px
    padding: 10px 10px 10px 20px
    @media only screen and (max-width: 575px)
      padding: 10px
  &__icon
    margin-right: 10px
    display: flex
    align-items: center
    @media only screen and (max-width: 575px)
      display: none
  &__input
    width: 100%
    color: #000
    font: 400 15px/18px "Lato", sans-serif
  &__button
    text-transform: uppercase
    font: 700 13px/16px "Lato", sans-serif
    color: #fff
    background-color: #BD0A2F
    padding: 10px 20px
    border-radius: 0 3px 3px 0
    svg
      display: none
    @media only screen and (max-width: 767px)
      svg
        display: block
      span
        display: none
    &:hover, &:active, &:focus
      background-color: #B1092C
  &__reset-button
    background-color: transparent
    padding: 5px 10px
