.catalog-area
  display: flex


.all-vacancy
  color: #BD0A2F
  font: 600 18px/32px "Lato", sans-serif
  margin-bottom: 6px


