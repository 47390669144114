@font-face {
 font-family: 'PT Sans';
 src: url('PTSans-Italic.woff2') format('woff2'),
 url('PTSans-Italic.woff') format('woff');
 font-weight: normal;
 font-style: italic;
 font-display: swap;
}

@font-face {
 font-family: 'PT Sans';
 src: url('PTSans-Bold.woff2') format('woff2'),
 url('PTSans-Bold.woff') format('woff');
 font-weight: bold;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: 'PT Sans';
 src: url('PTSans-Regular.woff2') format('woff2'),
 url('PTSans-Regular.woff') format('woff');
 font-weight: normal;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: 'PT Sans';
 src: url('PTSans-Italic.woff2') format('woff2'),
 url('PTSans-Italic.woff') format('woff');
 font-weight: normal;
 font-style: italic;
 font-display: swap;
}