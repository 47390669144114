
.main-brand-slider-area
  padding-bottom: 50px
  padding-top: 30px

.brands-slide
  width: 115px
  height: 50px
  display: flex
  align-items: center

  img
    object-position: center


.slider-type-arrow
  user-select: none
  position: relative
  .swiper-button-prev,
  .swiper-button-next
    width: 40px
    height: 20px
    top: 13px
    margin-top: auto
    background-image: url("../../../../assets/images/slider-arrow.png")
    &:after
      display: none
  .swiper-button-prev
    left: -50px
  .swiper-button-next
    right: -50px
    transform: rotate(180deg)
