.modal-interview
  position: relative
  .kto_prochital_tot_chereshnya
    display: none
  .btn-close
    top: 1.25rem
    right: 1.25rem
    position: absolute
  .modal-header
    padding: 15px 20px 0 15px
    border: none
    z-index: 10
    position: relative
  .modal-title
    color: #38454E
    font: 700 1.5rem/1.8125rem 'Lato', sans-serif
    margin-bottom: 10px
    padding-right: 10px
  .modal-body
    padding: 0px 15px 15px 15px
  .form__text-red
    //display: none
    margin-bottom: 0px
    display: block!important
  .form-main-area
    margin: 0
  [data-id="container"]
    padding: 0
  .form
    padding: 0
    box-shadow: none
    .form-col
      width: 100%
      flex: 1 1 100%
    .button__file
      margin-bottom: 10px
  [data-id="main-section-form-image"]
    display: none
  [data-id="main-section-form"]
    padding: 0
    overflow: inherit
  .w
    margin-top: 15px

.button__red--no-click
  opacity: 0.5
  pointer-events: none

