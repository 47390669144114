.dignities-list
  display: flex
  flex-direction: column
  max-width: 610px
  width: 100%
  .dignities-item:last-child
    margin-bottom: 0
    @media only screen and (max-width: 767px)
      margin-bottom: 20px



.dignities-item
  display: flex
  align-items: flex-start
  margin-bottom: 30px
  @media only screen and (max-width: 1023px)
    margin-bottom: 20px
  &__image
    flex: 0 0 232px
    border-radius: 3px
    overflow: hidden
    height: 142px
    display: flex
    margin-right: 30px
    @media only screen and (max-width: 767px)
      flex: 0 0 215px
      margin-right: 10px
      height: 150px
      justify-content: center
  &__info
    display: flex
    flex-direction: column
  &__title
    font: 600 21px/32px "Lato", sans-serif
    margin-bottom: 9px
    @media only screen and (max-width: 767px)
      margin-bottom: 8px
  &__text
    color: #000000
    margin-bottom: 7px
    font: 400 15px/21px "Lato", sans-serif
    position: relative
    @media only screen and (max-width: 1023px)
      font: 400 14px/18px "Lato", sans-serif
    @media only screen and (max-width: 767px)
      font: 400 14px/18px "Lato", sans-serif
  &__li
    .dignities-item__text
      padding-left: 15px
      margin-bottom: 0
      &:before
        content: ''
        left: 0
        top: calc(50% - 2.5px)
        position: absolute
        width: 5px
        height: 5px
        background-color: #BD0A2F
        border-radius: 50%


.dignities-main
  flex: 0 0 350px
  @media only screen and (max-width: 1023px)
    flex: 0 0 230px
  @media only screen and (max-width: 767px)
    flex: 1 1 auto

.dignities-main-item
  width: 100%
  display: flex
  flex-direction: column
  @media only screen and (max-width: 767px)
    flex-direction: row
  &__image
    height: 180px
    margin-bottom: 23px
    display: flex
    justify-content: center
    @media only screen and (max-width: 1023px)
      height: auto
      margin-bottom: 10px
    @media only screen and (max-width: 767px)
      flex: 0 0 215px
      margin-right: 10px
      //height: 150px
      margin-bottom: 0
    img
      object-fit: cover
      object-position: center
      @media only screen and (max-width: 767px)
        //height: 100%

.dignities-item-status-area
  display: flex
  justify-content: flex-end

.dignities-item-status
  margin-top: 10px
  border-top: 1px solid #B6BABD
  color: #333333
  padding-top: 5px
  font: 400 14px/21px "Lato", sans-serif
  strong
    color: #000000
    font: 700 16px/19px "Lato", sans-serif


@media only screen and (max-width: 575px)
  .dignities-item,
  .dignities-main-item
    flex-direction: column
    margin-bottom: 25px
    &__image
      flex: 1 1 auto
      max-height: 150px
      width: 100%
      margin-right: 0
      margin-bottom: 10px
      img
        //width: 100%
@media only screen and (max-width: 420px)
  .dignities-item,
  .dignities-main-item
    &__image img
      width: 100%

.dream-job-widget-area
  padding-top: 30px
  display: flex
  align-items: center
  justify-content: center

.dream-job-widget
