*
  box-sizing: border-box


body
  margin: 0

p
  margin-block-start: 0
  margin-block-end: 0
  

ul
  list-style-type: none
  margin-block-start: 0
  margin-block-end: 0
  padding-inline-start: 0

a
  text-decoration: none

.container-fluid
  width: 100%
  margin: 0
  padding: 0

.footer--top
  background: #273D54
.footer--bottom
  background: #fff




.grid-footer
  display: grid
  grid-template-rows: repeat(5,auto)
  // grid-template-columns: repeat(4, 1fr)
  grid-template-columns: 1.5fr 1.3fr 1fr 1.2fr
  gap: 16px
  align-items: start
  justify-items: start
  @media all and ($large)
    grid-template-columns: 1.5fr 1fr 1fr 1.2fr
  @media all and ($medium)
    grid-template-columns: 1.75fr 0fr 1fr 1.2fr
  @media all and ($small)
    grid-template-columns: 1fr 0fr 0fr 1fr
  @media all and ($extraSmall)
    gap: 10px
    grid-template-columns: 1fr
    justify-items: center
    // grid-template-columns: repeat(6, auto)
  &--bottom
    grid-template-rows: 1fr
    grid-template-columns: 1fr

    
  &__logo
    grid-row: 1/2
    grid-column: 1/2
    @media all and ($small)
      grid-row: 2/3
      grid-column: 1/2
    @media all and ($extraSmall)
      grid-row: 2/3
      grid-column: 1/2
    
  &__text
    grid-row: 2/4 
    grid-column: 1/2
    @media all and ($small)
      grid-row: 3/5
      grid-column: 1/2
  &__button
    grid-row: 4/5
    grid-column: 1/2
    @media all and ($small)
      grid-row: 5/6
    @media all and ($extraSmall)
      grid-row: 6/7
      grid-column: 1/2
  &__link
    grid-row: 5/6
    grid-column: 1/2
   
    @media all and ($small)
      grid-row: 6/7
    @media all and ($extraSmall)
      grid-row: 3/4
      grid-column: 1/2
  &__nav
    grid-row: 1/6
    grid-column: 3/4
    justify-self: center
    @media all and ($small)
      grid-row: 1/2
      grid-column: 1/5
    @media all and ($extraSmall)
      grid-column: 1/2
  &__contact
    grid-row: 1/6
    grid-column: 4/5
    @media all and ($small)
      justify-self: end
      grid-row: 2/7
    @media all and ($extraSmall)
      grid-row: 4/6 
      grid-column: 1/2
      justify-self: center


  


.footer 
  &__grid
    padding-top: 35px
    padding-bottom: 35px
  &__logo-wrapper
    padding-bottom: 15px
    @media all and ($extraSmall)
      padding-bottom: 5px
  &__text
    font: 600 14px/130% 'Lato', sans-serif
    color: #fff
    @media all and ($medium)
      font: 600 13px/130% 'Lato', sans-serif
    @media all and ($extraSmall)
      display: none
  &__button
    cursor: pointer
    border: 1px solid #FFFFFF
    border-radius: 1px
    font: 700 13px/16px 'Lato', sans-serif
    color: #fff
    text-transform: uppercase
    padding: 12px 22px
    background: transparent
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out
    &:hover,
    &:active,
    &:focus
      color: #273D54
      background: #FFFFFF
    @media all and ($extraSmall)
      padding: 7px 27px
      font: 700 12px/16px 'Lato', sans-serif
  &__link
    font: 400 14px/28px 'Lato', sans-serif
    text-decoration-line: underline
    color: #fff
    transition: font-weight 0.3s ease-in-out
    &:hover,
    &:active,
    &:focus
      font-weight: 700
    @media all and ($small)
      font: 400 13px/28px 'Lato', sans-serif
  &__nav-item
    padding-bottom: 18px
    &:last-child
      padding-bottom: 0
  &__nav
    @media all and ($small)
      width: 100%
      overflow: auto
  &__nav-list
    padding-top: 11px
    @media all and ($small)
      max-width: 735px
      display: flex
      gap: 35px
      padding-top: 0
      justify-content: space-between
  &__nav-link
    white-space: nowrap
    font: 400 16px/19px 'Lato', sans-serif
    color: #fff
    position: relative
    text-decoration: underline
    text-decoration-color: transparent
    transition: text-decoration-color 0.3s ease-in-out
    &:hover,
    &:active,
    &:focus
      text-decoration-color: #fff
    &::before
      content: ""
      width: 7px
      height: 7px
      border-radius: 10px
      background: #fff
      position: absolute
      left: -16px
      top: 50%
      transform: translateY(-50%)
      @media all and ($small)
        display: none
  &__contacts
    @media all and ($extraSmall)
      padding-bottom: 12px
  &__contacts-title
    font: 800 22px/30px 'Lato', sans-serif
    color: #fff
    padding-bottom: 15px
    @media all and ($medium)
      padding-bottom: 20px
    @media all and ($extraSmall)
      display: none
  &__contacts-item
    padding-bottom: 20px
    @media all and ($extraSmall)
      padding-bottom: 9px
    &:last-child
      padding-bottom: 0
  &__contacts-link
    color: #fff
    position: relative
    padding-left: 27px
    text-decoration: underline
    text-decoration-color: transparent
    transition: text-decoration-color 0.3s ease-in-out
    &:hover,
    &:active,
    &:focus
      text-decoration-color: #fff
    &--tel
      font: 700 15px/19px 'Lato', sans-serif
      @media all and ($medium)
        font-size: 14px
      &::before
        content: url(/assets/img/tel.svg)
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)
    &--mail
      font: 400 16px/19px 'Lato', sans-serif
      &::before
        content: url(/assets/img/mail.svg)
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)
    &--site
      font: 400 16px/19px 'Lato', sans-serif
      &::before
        content: url(/assets/img/site.svg)
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)
  &__bottom-wrapper
    padding: 15px 0
    @media all and ($extraSmall)
      padding: 10px
  &__bottom-text
    font: 400 13px/150% 'Lato', sans-serif
    color: #626060
    @media all and ($small)
      font: 400 12px/16px 'Lato', sans-serif



