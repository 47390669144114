.filter-area
  flex: 0 0 235px
  margin-right: 30px
  position: relative
  @media only screen and (max-width: 767px)
    display: none
.filter
  display: flex
  flex-direction: column
  margin-bottom: 10px
  &__header
    position: relative
    display: flex
    justify-content: space-between
    padding: 10px 20px
    background-color: #679CCE
    color: #fff
    font: 800 18px/21px "Lato", sans-serif
  &__body
    background-color: #fff
    padding: 7px 10px
  &__clean-button
    color: #333333
    margin-bottom: 20px
    padding: 0 10px
    font: 400 14px/16px "Lato", sans-serif
    background-color: transparent
    span
      border-bottom: 1px solid #333333

.filter-item
  margin-bottom: 15px
  &:last-child
    margin-bottom: 0
    .filter-item__row:last-child
      border-bottom: none
  &__title
    color: #3B4851
    font: 700 16px/19px "Lato", sans-serif
    margin-bottom: 5px
    padding: 0 10px
  &__row
    display: flex
    padding: 7px 0
    border-bottom: 1px solid #E8E8E8
    align-items: baseline
  &__row-text
    color: #333333
    font: 400 13px/15px "Lato", sans-serif
  &__row-count
    display: inline-block
    color: #000
    font: 500 11px/13px "Lato", sans-serif
    margin-left: auto
    padding-left: 10px

.filter-show-button
  width: 100%
  color: #000
  background-color: #FFFFFF
  border-radius: 3px
  padding: 10px
  transition: 0.3s ease color
  border: 1px solid #DBDBDB
  font: 400 13px/15px "Lato", sans-serif
  &:hover, &:active, &:focus
    color: #BD0A2F

.filter-drop-block
  position: absolute
  right: -10px
  background-color: #FFFFFF
  z-index: 10
  transform: translateX(100%)
  transition: 0.2s ease all
  cursor: pointer
  &__result
    border: 1px solid #DBDBDB
    border-radius: 3px
    padding: 10px
    color: #333333
    font: 400 13px/15px "Lato", sans-serif
  @media only screen and (max-width: 767px)
    display: none!important
