.swiper-wrapper
  z-index: 15
.swiper-with-pagination
  padding-bottom: 25px
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom, .swiper-pagination-fraction
    bottom: -2px

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet
    margin: 0 2.5px
    width: 28px
    height: 5px
    border-radius: 3px
    opacity: 1

  .swiper-pagination-bullet
    background-color: #CBCCCF
  .swiper-pagination-bullet-active
    opacity: 1
    background-color: #ABABAE

.selected-count-loading
  border-radius: 3px
  height: 32px
  margin-bottom: 5px


.checkbox
  width: 100%
  display: flex
  align-items: baseline
  cursor: pointer
  input
    display: none
  input:checked + .checkbox-cub
      background-color: #E4E9EE
      //border-color: #E4E9EE
      background-image: url("../../assets/images/check.png")
      background-position: center
      background-size: 75%
      background-repeat: no-repeat
  .checkbox-cub
    width: 12px
    height: 12px
    flex: 0 0 12px
    border: 1px solid #606060
    margin-right: 10px
  .checkbox-content
    width: 100%
    display: flex
    justify-content: space-between
    align-items: baseline

.d-block
  display: block

.d-none
  display: none

.custom-value-container
  padding-left: 0!important
  padding-right: 0!important
  overflow: hidden!important

.custom-menu-list
  padding-top: 5px!important
  padding-bottom: 5px!important
  border-radius: 5px
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)
  .custom-option:last-child
    &:before
      display: none

.custom-option
  padding: 0 10px!important
  position: relative
  &:before
    content: ''
    width: calc(100% - 20px)
    height: 1px
    position: absolute
    bottom: 0
    left: 10px
    right: 10px
    background-color: #B6BABD
  //background-color: transparent
  &:hover
    cursor: pointer
    //background-color: red
  &__border
    padding: 5px 0

  &__item
    color: #000
    //padding-bottom: 15px
    font: 400 15px/18px "Lato", sans-serif

.custom-control
  padding-left: 0!important

.custom-input
  //display: none!important
  opacity: 0!important
  position: absolute!important

[role="dialog"]
  padding: 0

.custom-container
  width: 100%

.ymaps-2-1-79-map
  width: 100%!important
  height: 100%!important

.swiper-wrapper
  cursor: pointer
.team-card-slider
  .swiper-wrapper
    cursor: default
    @media all and (max-width: 767px)
      cursor: pointer

